import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nfts"
    }}>{`NFTs`}</h1>
    <hr></hr>
    <p>{`ตัวละครทุกตัวและผืนฟาร์มทุกผืนในเกมนี้ คือ Non-fungible token หรือ NFT หมายความว่าหากคุณมีตัวละครหรือผืนฟาร์มแล้ว คุณจะเป็นเจ้าของมันแต่เพียงผู้เดียว`}</p>
    <p>{`Country Life : Farming Season ใช้ ERC-721 ในการสร้าง Non-fungible token ผู้เล่นจะสามารถจัดการ NFT ของตนเองได้อย่างสะดวกสบาย บน Polygon Chain`}</p>
    <p><img alt="polygon" src={require("./public/images/polygon.png")} /></p>
    <hr></hr>
    <h2 {...{
      "id": "ตัวละคร"
    }}>{`ตัวละคร`}</h2>
    <p>{`ผู้เล่นสามารถสร้างตัวละคร NFT ได้จากกล่องสุ่มตัวละคร หรือจะซื้อตัวละครจากตลาดซื้อขายก็ได้`}</p>
    <p><img alt="coin" src={require("./public/images/char.png")} /></p>
    <ul>
      <li parentName="ul">{`โอกาสได้รับตัวละครจากกล่องสุ่มตัวละคร`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ความหายาก`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`โอกาสได้รับ`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rare`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Super Rare`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Legend`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mythic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2%`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`ตัวละครที่มีระกับความหายากสูง จะมีโอกาสได้รับรางวัลจากเควสสูงขึ้นด้วย`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`Character Skill`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ความสามารถ`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`คำอธิบาย`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Expert Farmer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ลดระยะเวลาของการทำงานในฟาร์ม`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The Explorer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ลดระยะเวลาในการออกสำรวจหาไอเทม`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Animal Lover`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`มีโอกาสที่จะได้รับผลผลิตพิเศษจากสัตว์`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Lucky Gatherer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`มีโอกาสที่จะได้รับผลผลิตพิเศษจากพืช`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Regeneration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ลดระยะเวลาเควสติดคูลดาวน์`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Merchant Master`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`มีโอกาสที่จะได้รับรางวัลสูงสุดจากเควส`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2 {...{
      "id": "ผืนฟาร์ม"
    }}>{`ผืนฟาร์ม`}</h2>
    <p>{`ผู้เล่นสามารถสร้างผืนฟาร์ม NFT ได้จากการซื้อเท่านั้น`}</p>
    <ul>
      <li parentName="ul">{`Standard Farmland`}</li>
      <li parentName="ul">{`Great Farmland`}</li>
    </ul>
    <p><img alt="coin" src={require("./public/images/howto-2.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      